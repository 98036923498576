import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="grid h-screen place-items-center bg-grey">
        <div>
          <h1 className="text-6xl font-bold text-purple-dark">Studio Figaro</h1>
          <h3 className="text-3xl text-purple-dark">Coming soon...</h3>
        </div>
      </div>
    </div>
  );
}

export default App;
